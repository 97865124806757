import React from "react";
import { useTranslation } from "react-i18next";
import NoImage from '../../../../assets/images/no-photo-available.png';
import LoadingSkeleton from "./LoadingSkeleton";
import { Link } from "react-router-dom";
import InfoExtract from "./InfoExtract";

const NaverBlog = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#eaeaea'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.name || ''}
                                </h3>
                            </div>
                            <table className="table table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Code')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : String(keyword_info?.code || '-')}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Blog Url')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : <Link target="_blank" className="text-primary text-decoration-underline" to={keyword_info?.blog_url || '-'}>
                                            {keyword_info?.blog_url || '-'}
                                        </Link>}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Follow Daily')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.follow_daily || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Categories')}</td>
                                        <td className="p-2 text-start">
                                            {!keyword_info?.name ? <LoadingSkeleton /> : Array.isArray(keyword_info?.categories) ? keyword_info?.categories.join(', ') : keyword_info?.categories || ''}
                                        </td>
                                    </tr>
                                    <InfoExtract
                                        triggerRefresh={triggerRefresh}
                                        keyword_id={keyword_info?.id}
                                        keyword_search_raw={keyword_info?.keyword_search_raw || ''}
                                        info_type_a={(keyword_info?.info_type_a || [])?.join("\n")}
                                        noun_type_b={(keyword_info?.noun_type_b || [])?.join("\n")}
                                        classTd1="text-muted p-2 bg-secondary-100"
                                        classTd2="p-2 text-start"
                                    />
                                </tbody>
                            </table>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default React.memo(NaverBlog);