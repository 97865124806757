import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import NoImage from '../../../../assets/images/no-photo-available.png';
import LoadingSkeleton from "./LoadingSkeleton";
import InfoExtract from "./InfoExtract";

const GolfPlayer = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column w-100 gap-3">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '300px',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#fff'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.name || ''}
                                    <span className="fs-16 ms-2">{!keyword_info?.name ? <LoadingSkeleton /> : `${keyword_info?.rank || ''}위`}</span>
                                </h3>
                            </div>
                            <table className="table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('League Code')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.league_code || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Code')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.code || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Country')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.country || '-'}</td>
                                    </tr>
                                </tbody>
                            </table >
                        </div >
                    </>
                </div >
                <div className="w-100 mt-2">
                    <div className="w-100">
                        <table className="table table-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td className="text-center align-middle py-2 bg-secondary-100" rowSpan={7} width="152px">
                                        <h6 className="mb-0">{i18n?.language === 'en' ? t('Season') : ''} {keyword_info?.season || ''} {i18n?.language === 'ko' ? t('Season') : ''}</h6>
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {t('Prize Money')}
                                    </td>
                                    <td className="text-center align-middle py-2" style={{ minWidth: '80px' }}>
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.reward}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Genesis Points')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.genesis_point}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Driving Distance')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.driving_distance}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Greens in Regulation')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.greens_in_regulation}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Average Putting')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.avg_putts}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Fairway Hit Percentage')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.driving_acc}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Top 10 Finish')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.top_10_finish}
                                    </td>
                                </tr>
                                <InfoExtract
                                    triggerRefresh={triggerRefresh}
                                    keyword_id={keyword_info?.id}
                                    keyword_search_raw={keyword_info?.keyword_search_raw || ''}
                                    info_type_a={(keyword_info?.info_type_a || [])?.join("\n")}
                                    noun_type_b={(keyword_info?.noun_type_b || [])?.join("\n")}
                                    classTd1="text-center align-middle bg-secondary-100 py2"
                                    classTd2="p-2 text-start"
                                    colSpanButton={3}
                                    colSpanTd2={2}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(GolfPlayer);