import { ICategory } from 'api/types/_category';
import { IKeyword } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CollapseTag from 'components/Common/CollapseTag';
import DropdownCategory from 'components/Common/DropdownCategory';
import DropdownOption from 'components/Common/DropdownOption';
import DropdownOptionCategory from 'components/Common/DropdownOptionCategory';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { COLOR_TYPE_SENTIMENT, ENUM_STATUS_KEYWORD_RANKING, FREQUENCY_OPTIONS, FREQUENCY_TYPE, STATUS_KEYWORD_RANKING_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from "moment";
import ModalDetailKeyword from 'pages/Keyword/ModalDetailKeyword';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import calculatePercentages from 'utils';
import NoImage from '../../../assets/images/no-photo-available.png';
import { addConfirmKeyword, cancelConfirmKeyword, changeStatusKeyword, deleteKeyword, downloadExcelKeywords, getAllCategoriesNormal, getKeyword, getQueryInfoApprove, getRankingMonitorSummaries, getKeywordsMonitor as onGetKeywordsMonitor, postKeyword, putKeyword, updateKeywordRaw } from "../../../store/thunks";
import ModalNewsSearchingKeyword from '../ModalNewsSearchingKeyword';
import AutoRefreshComponent from './components/AutoRefreshComponent';
import TooltipHtmlCustom from 'components/Common/TooltipHtmlCustom';


const typeQuery = {
  'key': StringParam,
}


const SORT_BY_DEFAULT = 'popular_index';
const ORDER_BY_DEFAULT = 'DESC';
const CATEGORY_SINGER_DEFAULT = '708';
const KEYWORD_DELETION_STATUS_DEFAULT = '1';


const RISING_NUMBER = 10;
const RISING_NUMBER_CHANGE_BACKGROUND = 40;


registerLocale("en", en);
registerLocale("ko", ko);


const RankingMonitoring = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const TWO_DATE_AGO_DEFAULT = `${moment(new Date(new Date().setDate(new Date().getDate() - 2))).format("Y-MM-DD")}`;

  const FREQUENCY_OPTIONS_LANG = FREQUENCY_OPTIONS?.filter((item) => Number(item?.value) <= 2)?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));


  const STATUS_KEYWORD_RANKING_OPTIONS_LANG = STATUS_KEYWORD_RANKING_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const refTabTopTable = useRef<any>(null);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    keyword: withDefault(StringParam, ''),
    category_id: withDefault(StringParam, CATEGORY_SINGER_DEFAULT),
    date: withDefault(StringParam, TWO_DATE_AGO_DEFAULT),
    frequency_type: withDefault(StringParam, FREQUENCY_OPTIONS_LANG[0]?.value),
    keyword_deletion_status: withDefault(StringParam, STATUS_KEYWORD_RANKING_OPTIONS_LANG[0].value),
    rank_diff: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.keywordsMonitor,
      isKeywordSuccess: state.isKeywordMonitorSuccess,
      isKeywordLoading: state.isKeywordMonitorLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(KeywordProperties);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [dateSearch, setDateSearch] = useState<string>(query?.date || TWO_DATE_AGO_DEFAULT);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_KEYWORD_RANKING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.keyword_deletion_status))[0]);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const [frequencySearch, setFrequencySearch] = useState<Option | null>(
    FREQUENCY_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.frequency_type)
    )[0]
  );

  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [idDelete, setIdDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [isOpenConfirmKeyword, setIsOpenConfirmKeyword] = useState<boolean>(false);
  const [isConfirmKeywordLoading, setIsConfirmKeywordLoading] = useState<boolean>(false);


  const [keyword, setKeyword] = useState<IKeyword | any | null>(null);

  const [keywordAnalysisNews, setKeywordAnalysisNews] = useState<{ keyword_id: number, date: string } | null>(null);

  const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<ICategory[]>([]);
  const [categoryDropdown, setCategoryDropdown] = useState<any>({});
  const [inputKeywords, setInputKeywords] = useState<string>('');

  const [itemUpdateKeyword, setItemUpdateKeyword] = useState<IKeyword | null>(null);

  const [keywordDetail, setKeywordDetail] = useState<IKeyword | null | Object>(null);

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [keywordAction, setKeywordAction] = useState<{ status: number } | null>(null);

  const [itemUpdateKeywordRaw, setItemUpdateKeywordRaw] = useState<{ keyword_id: number, keyword: string } | null>(null);
  const [isLoadingUpdateKeywordRaw, setIsLoadingUpdateKeywordRaw] = useState<boolean>(false);


  const [summaries, setSummaries] = useState<{ full_total: number, active_total: number, hide_total: number, exclude_total: number, }>({ full_total: 0, active_total: 0, hide_total: 0, exclude_total: 0, });
  const [isSummariesLoading, setIsSummariesLoading] = useState<boolean>(false);

  const [queryInfoApprove, setQueryInfoApprove] = useState<{ keyword_confirm_flg: number, confirm_time: string, admin_confirm: any, } | null>(null);

  const searchData = () => {
    setOptionsSelected((prev: any) => []);
    setQuery({
      ...query,
      page: 1,
      keyword: keywordSearch || '',
      category_id: categorySearch?.value || '',
      frequency_type: frequencySearch?.value,
      date: dateSearch,
      keyword_deletion_status: statusSearch?.value || '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      frequencySearch: FREQUENCY_OPTIONS_LANG[0]?.value,
      date: TWO_DATE_AGO_DEFAULT,
      keyword: '',
      category_id: CATEGORY_SINGER_DEFAULT,
      keyword_deletion_status: STATUS_KEYWORD_RANKING_OPTIONS_LANG[0].value,
      rank_diff: '',
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch(TWO_DATE_AGO_DEFAULT);
    setKeywordSearch("");
    setCategorySearch({ label: '', value: CATEGORY_SINGER_DEFAULT });
    setFrequencySearch(FREQUENCY_OPTIONS_LANG[0]);
    setStatusSearch(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]);
  };

  const handleQuerySummariesData = async () => {
    try {
      setIsSummariesLoading((prev) => true);
      const res: any = await getRankingMonitorSummaries(query);
      setSummaries((prev: any) => ({
        full_total: res?.data?.full_total || 0,
        active_total: res?.data?.active_total || 0,
        hide_total: res?.data?.hide_total || 0,
        exclude_total: res?.data?.exclude_total || 0,
      }));
      setIsSummariesLoading((prev) => false);
    } catch (error: any) {
      setIsSummariesLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleQueryInfoApprove = async () => {
    try {
      const res: any = await getQueryInfoApprove({
        frequency_type: query?.frequency_type,
        date: query?.date,
      });
      setQueryInfoApprove((prev: any) => ({
        keyword_confirm_flg: res?.data?.keyword_confirm_flg || 0,
        confirm_time: res?.data?.confirm_time || '',
        admin_confirm: res?.data?.admin_confirm,
      }));
    } catch (error: any) {
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  useEffect(() => {
    dispatch(onGetKeywordsMonitor(query));
    if (Number(query?.page) === 1) {
      handleQuerySummariesData();
      handleQueryInfoApprove();
    }
  }, [dispatch, query]);

  const changeStatus = (status_search: any) => {
    const queryNew = {
      ...query,
      rank_diff: '',
      keyword_deletion_status: status_search?.value || '',
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setStatusSearch(status_search);
    setOptionsSelected((prev: any) => []);
  };

  const changeRankDiff = (rank_diff_search: any) => {
    const queryNew = {
      ...query,
      keyword_deletion_status: '',
      rank_diff: rank_diff_search || '',
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setOptionsSelected((prev: any) => []);
    setStatusSearch(null);
  };

  const changeLimitPerPage = (limit: number) => {
    const queryNew = {
      ...query,
      limit: limit,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
  };


  const handleUpdateKeywordRaw = async () => {
    try {
      if (!itemUpdateKeywordRaw) {
        return;
      }
      setIsLoadingUpdateKeywordRaw((_prev) => true);
      const response: any = await updateKeywordRaw(itemUpdateKeywordRaw);
      setIsLoadingUpdateKeywordRaw((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeywordRaw((_prev) => null);
        dispatch(onGetKeywordsMonitor(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdateKeywordRaw((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywords?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.id }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywords?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 50,
        thComponent: () => (<>
          <div className="form-check" >
            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            {(countRowSelected > 0) && <span className="position-absolute translate-middle badge bg-primary rounded-pill"
              style={{ top: '-7px', left: '35%' }}
            >
              {countRowSelected || 0}
            </span>
            }
          </div>
        </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className={`${Number(item?.rank_diff) >= RISING_NUMBER_CHANGE_BACKGROUND ? 'row-bg-danger' : ''} form-check`}>
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.id })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('Ranking'),
        accessor: "ranking",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ width: '60px' }} className={Number(item?.rank_diff) >= RISING_NUMBER_CHANGE_BACKGROUND ? 'row-bg-danger' : ''}>
              <div className="text-center">{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
              {(Number(item?.rank_diff) === 0) ? <div className="text-center fs-13 text-muted">-</div> : (
                <div className={`${Number(item?.rank_diff) === 0 ? '' : (Number(item?.rank_diff) < 0 ? 'text-primary' : 'text-danger')} text-center fs-13`}>
                  {formatNumberWithCommas(item?.rank_diff)}
                  {Number(item?.rank_diff) === 0 ? <></> : (Number(item?.rank_diff) < 0 ? <span style={{ fontSize: '10px' }}>▼</span> : <span style={{ fontSize: '10px' }}>▲</span>)}
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: t('Keyword ID'),
        accessor: "id",
        filterable: false,
        sortable: true,
        thWidth: 120,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className={`text-center pe-3 ${Number(item?.rank_diff) >= RISING_NUMBER_CHANGE_BACKGROUND ? 'row-bg-danger' : ''}`}>
              {item?.id || ''}
            </div>
          )
        },
      },
      {
        Header: t('Status'),
        accessor: "keyword_deletion_status",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: 'text-center align-middle',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className={`${Number(item?.rank_diff) >= RISING_NUMBER_CHANGE_BACKGROUND ? 'row-bg-danger' : ''} text-center`} style={{ minWidth: '65px' }}>
              <span className={`rounded-pill badge bg-${['secondary', 'success', 'danger', 'warning'][Number(cell?.value || 0)] || 'success'} ${!!item?.is_auto_action ? 'badge-tooltip-auto' : ''} auto_flg_${i18n?.language}`}>{STATUS_KEYWORD_RANKING_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value || 0))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const info: any = item?.keyword_info;
          return (
            <TooltipHtmlCustom html_dom={<img
              alt={keyword?.keyword || ''}
              loading="lazy" src={item?.image_url || NoImage}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
                backgroundColor: '#eaeaea',
                maxWidth: '700px',
                borderRadius: '8px',
              }}
            />}>
              <div className={`cursor-pointer d-flex align-items-center justify-content-between ${Number(item?.rank_diff) >= RISING_NUMBER_CHANGE_BACKGROUND ? 'row-bg-danger' : ''}`} style={{ minWidth: "170px" }} onClick={(e) => {
                e.stopPropagation();
                handleCallKeywordDetail(item);
              }}>
                <div style={{ width: '42px', height: '42px', border: '1px solid rgb(230, 230, 230)', borderRadius: '4px', overflow: 'hidden' }} className="me-2 text-center">
                  <img
                    alt={keyword?.keyword || ''}
                    src={item?.image_url || NoImage}
                    style={{
                      width: 'auto',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                      // backgroundColor: '#eaeaea'
                    }}
                  />
                </div>
                <div style={{ width: '100px' }}>{cell?.value}</div>
              </div >
            </TooltipHtmlCustom>
          )
        },
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        thWidth: 280,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = !!value?.category ? [value?.category] : [];
          let categoryName: string[] = [];
          for (let category of categories) {
            if (category?.depth > 1) {
              let subs = [];
              for (let sub of category?.group_categories) {
                subs.push(sub?.name);
              }
              subs.push(`<strong>${category?.name}</strong>`);
              categoryName.push(subs.join(` > `));
            } else {
              categoryName.push(category?.name || '');
            }
          }

          return (
            <div style={{ minWidth: '280px' }}>
              <span dangerouslySetInnerHTML={{ __html: categoryName[0] }} />
            </div>
          );
        },
      },
      {
        Header: t("Popular Index"),
        accessor: "popular_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Issue Index"),
        accessor: "index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("P Index"),
        accessor: "p_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "align-middle text-end",
        description: t("Participation Index"),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Search Volume"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 450,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={3}>
                  {t("Search Volume")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'search_index', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'search_index') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t("Search Index")}
                  {query?.sort_by === 'search_index' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Search Volume")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("News Weight")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "150px" }}>
                {formatNumberWithCommas(item.search_index)}
              </div>
              <div
                style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}
              >
                {formatNumberWithCommas(item.search_value)}
              </div>
              <div style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.news_ratio)} %
              </div>
            </div>
          );
        },
      },
      {
        Header: t("News"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 600,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={4}>
                  {t("News")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'news_index', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'news_index') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t("News Index")}
                  {query?.sort_by === 'news_index' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Title Count")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Assigned News")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "150px" }}
                >
                  {t("Total News")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "150px" }}>
                {formatNumberWithCommas(item.news_index)}
              </div>
              <div style={{ width: "150px", borderLeft: "1px solid #eaeaea" }} >
                {formatNumberWithCommas(item.news_title_count)}
              </div>
              <div
                className="cursor-pointer link-primary text-decoration-underline"
                style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}
                onClick={() => setKeywordAnalysisNews((_prev) => ({ keyword_id: item?.id, date: query?.date || TWO_DATE_AGO_DEFAULT }))}
              >
                {formatNumberWithCommas(item.news_count)}
              </div>
              <div style={{ width: "150px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.news_total)}
              </div>
            </div>
          );
        },
      },
      {
        Header: t('Viral Index'),
        accessor: "viral_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t('Activity Index'),
        accessor: "activity_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "align-middle text-end",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: '120px' }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Gender"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={2}>
                  {t("Gender")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Male")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Female")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_male_value || 0,
            item.search_female_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_male_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_female_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "6",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Age"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        thWidth: 500,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Age")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("10")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("20")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("30")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("40")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("50")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_age_10_value || 0,
            item.search_age_20_value || 0,
            item.search_age_30_value || 0,
            item.search_age_40_value || 0,
            item.search_age_50_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_10_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_20_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_30_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[2]} %)</span>
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.search_age_40_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[3]} %)</span>
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_50_value)}
                <br />
                <span className="text-muted fs-13">({arrPercent[4]} %)</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: t("Sentiment"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 400,
        thComponent: () => (
          <table>
            <thead className="none-sticky">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Sentiment")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky align-top"
                  style={{ width: "100px" }}
                >
                  {t("Sentiment")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Positive")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Negative")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                >
                  {t("Neutral")}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item?.sentiment?.total_positive || 0,
            item?.sentiment?.total_negative || 0,
            item?.sentiment?.total_neutral || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }} className={`align-middle align-self-center text-${COLOR_TYPE_SENTIMENT?.[`${item?.sentiment?.sentiment_value || '-'}`] || 'muted'}`}>
                {t(`${item?.sentiment?.sentiment_value || '-'}`)}
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item?.sentiment?.total_positive)}
                <br />
                <span className="text-muted fs-13">({arrPercent[0]} %)</span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item?.sentiment?.total_negative)}
                <br />
                <span className="text-muted fs-13">({arrPercent[1]} %)</span>
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item?.sentiment?.total_neutral)}
                <br />
                <span className="text-muted fs-13">({arrPercent[2]} %)</span>
              </div>
            </div>
          );
        },
      },
    ],
    [i18n?.language, JSON.stringify(optionsSelected), JSON.stringify(keywords?.list), userPermissions, JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangePicker = (value: any) => {
    setDateSearch((_prev) => value ? moment(value).format("Y-MM-DD") : '');
  }

  const handleChangeDatePicker = (date: any) => {
    const frequencyValue = String(frequencySearch?.value);
    switch (frequencyValue) {
      case String(FREQUENCY_TYPE.WEEKLY):
        handleChangePicker(moment(date).startOf('isoWeek').toDate());
        break;
      case String(FREQUENCY_TYPE.DAILY):
        handleChangePicker(date);
        break;
      default:
        throw new Error('Invalid frequency type');
    }
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IKeyword) => {
    setIdDelete((_prev) => item?.id);
  };

  const handleActionDelete = async () => {
    if (!idDelete) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteKeyword(idDelete);
      if (response?.code === 200) {
        dispatch(onGetKeywordsMonitor(query));
        (Number(query?.page) !== 1) && handleQuerySummariesData();
        setIsConfirmLoading((_prev) => false);
        setIdDelete((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdDelete((_prev) => null);
  }
  // End::Delete


  // Begin::Confirm
  const handleConfirmKeyword = () => {
    setIsOpenConfirmKeyword((_prev) => true);
  };

  const handleActionConfirmKeyword = async () => {
    try {
      setIsConfirmKeywordLoading((_prev) => true);
      const dataForm = {
        date: query?.date,
        frequency_type: query?.frequency_type
      };
      const response: any = !!Number(keywords?.keyword_confirm_flg) ? await cancelConfirmKeyword(dataForm) : await addConfirmKeyword(dataForm);
      if (response?.code === 200) {
        dispatch(onGetKeywordsMonitor(query));
        setIsConfirmKeywordLoading((_prev) => false);
        setIsOpenConfirmKeyword((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmKeywordLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmKeywordLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmKeywordClick = () => {
    setIsOpenConfirmKeyword((_prev) => false);
  }
  // End::Confirm



  // Begin::Update status
  const handleConfirmChangeStatus = (item: { status: number }) => {
    setKeywordAction((_prev) => item);
  };

  const handleActionChangeStatus = async () => {
    if (!keywordAction) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const kw_s = (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await changeStatusKeyword({
        ...keywordAction,
        keyword_ids: kw_s,
        frequency_type: frequencySearch?.value,
        date: dateSearch,
      });
      if (response?.code === 200) {
        dispatch(onGetKeywordsMonitor(query));
        (Number(query?.page) !== 1) && handleQuerySummariesData();
        setIsConfirmLoading((_prev) => false);
        setKeywordAction((_prev) => null);
        setOptionsSelected((prev: any) => []);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmChangeStatusClick = () => {
    setKeywordAction((_prev) => null);
  }
  // End::Update status

  const handleUpdateKeyword = async () => {
    try {
      if (!itemUpdateKeyword) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putKeyword(itemUpdateKeyword?.id, { keyword: itemUpdateKeyword?.keyword });
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeyword((_prev) => null);
        dispatch(onGetKeywordsMonitor(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleActionAddKeyword = async () => {
    try {
      setIsLoading((_prev) => true);
      const dataForm = {
        category_id: categoryDropdown?.level_5?.value || categoryDropdown?.level_4?.value || categoryDropdown?.level_3?.value || categoryDropdown?.level_2?.value || categoryDropdown?.level_1?.value,
        keywords: String(inputKeywords || "")
          .split("\n")
          ?.reduce((arr: string[], item: string) => {
            return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
          }, [])
      };
      const response: any = await postKeyword(dataForm);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setKeyword((prev: any) => null);
        setCategoryDropdown((prev: any) => ({}));
        setInputKeywords((prev: any) => '');
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        dispatch(onGetKeywordsMonitor(query));
        (Number(query?.page) !== 1) && handleQuerySummariesData();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleCallKeywordDetail = async (item: IKeyword) => {
    try {
      setKeywordDetail(() => ({ keyword_type: item?.keyword_type }));
      const response: any = await getKeyword(item?.id);
      if (response?.code === 200) {
        setKeywordDetail(() => (response?.data));
      } else {
        setKeywordDetail(() => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setKeywordDetail(() => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseKeywordDetailClick = () => {
    setKeywordDetail(() => null);
  };

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelKeywords({ ...query, page: 1 });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'keywords';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const onHideKeywordAnalysisNews = () => {
    setKeywordAnalysisNews((_prev) => null);
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {

        const [res1]: any = await Promise.all([getAllCategoriesNormal({})]);
        setOptionCategoryDropdown((_prev: any) => res1?.data || []);
        if (query?.category_id) {
          setCategorySearch((_prev) => {
            return ({
              label: '',
              value: `${query?.category_id}`,
            });
          });
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!query?.category_id) {
      setCategorySearch({ label: t('All Category'), value: '' });
    }
    document.title = `${t('Ranking Monitoring')} - ${t('Monitoring')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Ranking Monitoring')} pageTitle={t('Monitoring')} />
          {isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING, userPermissions) && (<>
            <div className="d-flex justify-content-end gap-3 align-items-center text-end my-2 ">
              <div className="d-flex justify-content-end align-items-center">
                <div className="me-1">
                  {t('Showcase')}
                </div>
                <ul className="pagination pagination-separated" role="navigation" >
                  <li className={`page-item ${String(query?.limit) === '50' ? 'active' : ''}`}>
                    <a rel="prev" role="button" href="/" className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLimitPerPage(50);
                      }}
                    >50</a>
                  </li>
                  <li className={`page-item ${String(query?.limit) === '100' ? 'active' : ''}`}>
                    <a rel="canonical" role="button" href="/" className="page-link" aria-current="page"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLimitPerPage(100);
                      }}
                    >100</a>
                  </li>
                  <li className={`page-item ${String(query?.limit) === '200' ? 'active' : ''}`}>
                    <a rel="next" role="button" href="/" className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLimitPerPage(200);
                      }}
                    >200</a>
                  </li>
                </ul>
              </div>
              <div className="d-flex ms-3">
                <div className="me-2">{t('Page Auto-Refresh')}</div>
                <AutoRefreshComponent refresher={searchData} />
              </div>
            </div>
            <Row>
              <Col sm={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-12 col-sm-6 col-md-6">
                        {(String(frequencySearch?.value) === String(FREQUENCY_TYPE.DAILY)) && <div className="d-flex align-items-center">
                          <div>
                            <div className="fs-12">{t('Approve')}: <strong className="text-primary">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? t('Approved') : t('Pending')}</strong></div>
                            <div className="fs-12">{t('Approver')}: <span className="text-muted">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? (queryInfoApprove?.admin_confirm?.email || '') : '-'}</span></div>
                            <div className="fs-12">{t('Time')}: <span className="text-muted">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? (queryInfoApprove?.confirm_time || '') : '-'}</span></div>
                          </div>
                        </div>
                        }
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 text-end">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleDownloadExcel}
                          disabled={isLoadingExportFile}
                        >
                          {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                          <span className="ms-1">{t('Button Download Excel')}</span>
                        </button>
                        {((String(frequencySearch?.value) === String(FREQUENCY_TYPE.DAILY)) && isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions)) && <button
                          type="button"
                          className="btn btn-info ms-3"
                          onClick={handleConfirmKeyword}
                          disabled={!!Number(queryInfoApprove?.keyword_confirm_flg)}
                        >
                          <span className="ms-1">{t('Approve')}</span>
                        </button>}
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Col md={12} xl={12}>
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Full')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.full_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Activated')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.active_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Hidden')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.hide_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Excluded')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.exclude_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Category')} isShow={!!categorySearch}>
                          <DropdownCategory
                            name="category"
                            placeholder={t('Category')}
                            isClearable={true}
                            dataList={optionCategoryDropdown || []}
                            initialValue={categorySearch ?? undefined}
                            onChangeCategory={(event) => {
                              setCategorySearch(event)
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Interval')} isShow={true}>
                          <DropdownOption
                            name="interval"
                            dataList={FREQUENCY_OPTIONS_LANG || []}
                            placeholder={`${t("Interval")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={frequencySearch || FREQUENCY_OPTIONS_LANG[0]}
                            onChangeSelect={(e: any) => setFrequencySearch(e)}
                            isHasOptionAll={false}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='date-picker-wrapper-custom z-hight mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                          <RangeDatePicker
                            frequencyTypeSearch={frequencySearch}
                            dateSearch={dateSearch}
                            handleChangeDatePicker={handleChangeDatePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={12} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-end justify-content-xxl-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isKeywordLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-1 sticky-menu-tab-top-table" ref={refTabTopTable}>
                    <Row>
                      <Col sm={12} md={6} xl={6} className="my-2">
                        <ul className="nav nav-tabs nav-tabs-custom nav-primary nav-justified nav nav-tabs" style={{ maxWidth: '400px', borderBottom: '0px solid #fff' }}>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${!query?.rank_diff && String(statusSearch?.value || '') === '' ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changeStatus(null);
                            }}
                          >{t('Full')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(statusSearch?.value || '') === String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]?.value) ? 'active' : ''}`} role="button" href="/" onClick={(e: any) => {
                            e.preventDefault();
                            changeStatus(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]);
                          }}
                          >{t('Activated')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(statusSearch?.value || '') === String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[2]?.value) ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changeStatus(STATUS_KEYWORD_RANKING_OPTIONS_LANG[2]);
                            }}
                          >{t('Hidden')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(statusSearch?.value || '') === String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[1]?.value) ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changeStatus(STATUS_KEYWORD_RANKING_OPTIONS_LANG[1]);
                            }}
                          >{t('Excluded')}</a>
                          </li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${!!query?.rank_diff ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changeRankDiff(RISING_NUMBER);
                            }}
                          >{t('Rising')}</a>
                          </li>
                        </ul>
                      </Col>
                      <Col sm={12} md={6} xl={6} className="my-2 text-end ">
                        {isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions) && <div className="px-3">
                          {(String(statusSearch?.value) !== String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[2]?.value)) && <button
                            type="button"
                            className="btn btn-soft-warning ms-2 "
                            style={{ width: '90px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.HIDDEN }) }}
                          >
                            {t('Hide')}
                          </button>}
                          {(String(statusSearch?.value) !== String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[1]?.value)) && <button
                            type="button"
                            className="btn btn-soft-danger ms-2 fs-14"
                            style={{ width: '90px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.EXCLUDED }) }}
                          >
                            {t('Exclude')}
                          </button>}
                          {(String(statusSearch?.value) !== String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]?.value)) && <button
                            type="button"
                            className="btn btn-soft-success ms-2 fs-14"
                            style={{ width: '90px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.ACTIVATED }) }}
                          >
                            {t('Activate')}
                          </button>}
                        </div>}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed sticky-table-ranking-monitoring"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={keywords?.list?.length ? keywords?.list : []}
                      textNoData={(keywords?.list?.length === 0 && !query?.category_id) ? t('Please select at least 1 category') : ''}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywords?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywords?.pagination?.total) / Number(keywords?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordLoading}
                      plusPaddingTopFixed={window.innerWidth >= 1000 ? refTabTopTable?.current?.clientHeight : 0}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>)}
          <ToastContainer closeButton={false} limit={1} />
        </Container>

        <Modal
          isOpen={keyword !== null}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={() => setKeyword(null)}>
            {t('Button Create Keyword')}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Category')}
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 1')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={optionCategoryDropdown || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_1 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_1: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 2')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_1?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_2 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_2: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 3')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_2?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_3 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_3: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 4')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_3?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_4 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_4: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 5')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_4?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_5 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({ ...prev, level_5: e }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Keyword')}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <Input
                    type="textarea"
                    rows={10}
                    id="keywords"
                    name="keywords"
                    autocomplete={false}
                    value={inputKeywords}
                    placeholder={`${t("Keyword")}...`}
                    onChange={(event: any) => {
                      setInputKeywords((prev: any) => (event?.target?.value || ''))
                    }}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => { !isLoading && setKeyword(null) }}
                  style={{ minWidth: '100px' }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading || (!categoryDropdown?.level_1?.value || !inputKeywords)}
                  onClick={handleActionAddKeyword}
                  style={{ minWidth: '100px' }}
                >
                  {isLoading ? (
                    <Spinner size="sm" ></Spinner>
                  ) : t("Button Update")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={itemUpdateKeyword !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateKeyword((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateKeyword((_prev) => null)}>
            {t('Button Update Keyword')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Keyword")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="keyword"
                  name="keyword"
                  autocomplete={false}
                  value={itemUpdateKeyword?.keyword}
                  placeholder={`${t("Keyword")}...`}
                  onChange={(event: any) => {
                    setItemUpdateKeyword((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  style={{ width: '100px' }}
                  onClick={() => { !isLoading && setItemUpdateKeyword((_prev) => null) }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoading}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateKeyword()}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!keywordAnalysisNews}
          centered={true}
          toggle={onHideKeywordAnalysisNews}
          size="xl"
          className="modal-fullscreen"
          scrollable={true}
          keyboard={true}
        >
          <ModalHeader toggle={onHideKeywordAnalysisNews}>
            {t('News Searching Keyword')}
          </ModalHeader>
          <ModalBody className="pt-3" style={{ minHeight: "450px" }}>
            <ModalNewsSearchingKeyword keywordAnalysisNews={keywordAnalysisNews} />
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Keyword')}
          isOpen={idDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <ModalDetailKeyword
          header={t("Keyword Description")}
          isShow={!!keywordDetail}
          keyword={keywordDetail}
          onClose={onCloseKeywordDetailClick}
        />
        <ModalConfirm
          header={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('Hide') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('Activate') : t('Exclude'))}
          textButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('Hide') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('Activate') : t('Exclude'))}
          classButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? 'btn-warning' : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? 'btn-success' : 'btn-danger')}
          title={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? `${t('Do you want to hide these keywords on the date [DATE]').replace('[DATE]', query?.date || '')}?` : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? `${t('Are you sure you want to activate this')}?` : `${t('Are you sure you want to exclude this keyword')}?`)}
          content={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('When hidden, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('After confirming the activate action, the data will be permanently activated and cannot be recovered. Do you want to proceed with the activate action.') : t('When excluded, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.'))}
          isOpen={keywordAction !== null}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmChangeStatusClick}
          onConfirm={handleActionChangeStatus}
        />
        <ModalConfirm
          header={!!Number(keywords?.keyword_confirm_flg) ? t('Unconfirm') : t('Approve')}
          textButtonConfirm={!!Number(keywords?.keyword_confirm_flg) ? t('Unconfirm') : t('Approve')}
          classButtonConfirm={'btn-soft-info'}
          title={!!Number(keywords?.keyword_confirm_flg) ? `${t('Are you sure you want to unconfirm this keywords')}?` : <div dangerouslySetInnerHTML={{ __html: `${t('Do you want to approve the [INTERVAL] rankings for [DATE]').replace('[DATE]', `<span class='d-inline-block'>${query?.date || ''}</span>`).replace('[INTERVAL]', query?.frequency_type === String(FREQUENCY_TYPE.DAILY) ? t('daily') : (query?.frequency_type === String(FREQUENCY_TYPE.WEEKLY) ? t('weekly') : ''))}?` }} />}
          content={!!Number(keywords?.keyword_confirm_flg) ? t('When unconfirmed, it will hide the keyword from the rankings, and the changes will be reflected right now.') : t('Upon approval, the ranking data will be published.')}
          isOpen={isOpenConfirmKeyword}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmKeywordLoading}
          onClose={onCloseConfirmKeywordClick}
          onConfirm={handleActionConfirmKeyword}
        />
        <Modal
          isOpen={itemUpdateKeywordRaw !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateKeywordRaw((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateKeywordRaw((_prev) => null)}>
            {t('Button Update')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Keyword")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="KeywordRaw"
                  name="KeywordRaw"
                  autocomplete={false}
                  value={itemUpdateKeywordRaw?.keyword}
                  placeholder={`${t("Keyword")}...`}
                  onChange={(event: any) => {
                    setItemUpdateKeywordRaw((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  disabled={isLoadingUpdateKeywordRaw}
                  style={{ width: '100px' }}
                  onClick={() => setItemUpdateKeywordRaw((_prev) => null)}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoadingUpdateKeywordRaw}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateKeywordRaw()}
                >
                  {isLoadingUpdateKeywordRaw ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment >
  );
};

export default RankingMonitoring;