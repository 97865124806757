const ROLES_FOR_APP = {
    DASHBOARD: "dashboard_view",

    ROLE_LIST: "role_view",
    ROLE_CREATE: "role_create",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_remove",

    OPERATOR_LIST: "operator_view",
    OPERATOR_CREATE: "operator_create",
    OPERATOR_UPDATE: "operator_update",
    OPERATOR_DELETE: "operator_remove",

    LOG_LIST: "activity_log_view", //

    INDEX_LOG: "index_log_view",
    INDEX_SETTING: "index_settings_view",
    GENERAL_SETTING: "general_settings_view",

    CATEGORY_MANAGEMENT: "category_trend_index_view", //
    CATEGORY_LIST: "category_list",
    CATEGORY_TREE: "category_tree",
    CATEGORY_CREATE: "category_create",
    CATEGORY_UPDATE: "category_update",
    CATEGORY_DELETE: "category_remove",
    CATEGORY_SETTING_INDEX_WEIGHT: "category_update",
    STOCK_CATEGORY_MANAGEMENT: "category_stock_view",

    KEYWORD_MANAGEMENT: "managements_keyword",
    KEYWORD_CREATE: "keyword_create",
    KEYWORD_UPDATE: "keyword_update",
    KEYWORD_DELETE: "keyword_remove",

    TREND_INDEX_KEYWORD_MANAGEMENT: "keyword_trend_index_view",
    TREND_INDEX_KEYWORD_CREATE: "keyword_trend_index_create",
    TREND_INDEX_KEYWORD_UPDATE: "keyword_trend_index_update",
    TREND_INDEX_KEYWORD_DELETE: "keyword_trend_index_remove",

    STOCK_KEYWORD_MANAGEMENT: "keyword_stock_view",
    STOCK_KEYWORD_CREATE: "keyword_stock_create",
    STOCK_KEYWORD_UPDATE: "keyword_stock_update",
    STOCK_KEYWORD_DELETE: "keyword_stock_remove",

    RANKING_MONITORING: "monitoring_ranking_view",
    RANKING_MONITORING_UPDATE: "monitoring_ranking_update",
    COLLECTING_MONITORING: "monitoring_collecting_view",
    
    CHART_RANKING_SINGER: "monitoring_ranking_view", //

    PARTICIPATION_MANAGEMENT: "managements_participation",
    PARTICIPATION__CREATE: "participation_create",
    PARTICIPATION__UPDATE: "participation_update",
    PARTICIPATION__DELETE: "participation_remove",

    PARTICIPATION_TEMPLATE_MANAGEMENT: "participation_template_view",
    PARTICIPATION_TEMPLATE_CREATE: "participation_template_create",
    PARTICIPATION_TEMPLATE_UPDATE: "participation_template_update",
    PARTICIPATION_TEMPLATE_DELETE: "participation_template_remove",

    PARTICIPATION_CATEGORY_MANAGEMENT: "participation_category_view",
    PARTICIPATION_CATEGORY_CREATE: "participation_category_create",
    PARTICIPATION_CATEGORY_UPDATE: "participation_category_update",
    PARTICIPATION_CATEGORY_DELETE: "participation_category_remove",

    PARTICIPATION_TICKET_MANAGEMENT: "participation_ticket_view",
    PARTICIPATION_TICKET_CREATE: "participation_ticket_create",
    PARTICIPATION_TICKET_UPDATE: "participation_ticket_update",
    PARTICIPATION_TICKET_DELETE: "participation_ticket_remove",

    TREND_INDEX_MANAGEMENT: "managements_trend_index",
    TREND_INDEX_CREATE: "trend_index_create",
    TREND_INDEX_UPDATE: "trend_index_update",
    TREND_INDEX_DELETE: "trend_index_remove",

    PAYMENT_MANAGEMENT: 'managements_payment',
    PAYMENT__CREATE: "payment_create",
    PAYMENT__UPDATE: "payment_update",
    PAYMENT__DELETE: "payment_remove",


    PAYMENT_HISTORY: 'payment_history_view',
    PAYMENT_HISTORY_CREATE: "payment_history_create",
    PAYMENT_HISTORY_UPDATE: "payment_history_update",
    PAYMENT_HISTORY_DELETE: "payment_history_remove",


    SUBSCRIPTION_MANAGEMENT: 'subscription_view',
    SUBSCRIPTION_CREATE: "subscription_create",
    SUBSCRIPTION_UPDATE: "subscription_update",
    SUBSCRIPTION_DELETE: "subscription_remove",


    INVESTMENT_INDEX_MANAGEMENT: "managements_investment_index",
    INVESTMENT_INDEX_CREATE: "investment_index_create",
    INVESTMENT_INDEX_UPDATE: "investment_index_update",
    INVESTMENT_INDEX_DELETE: "investment_index_remove",

    SEARCH_INDEX_MANAGEMENT: "index_search_view",
    SEARCH_INDEX_CREATE: "index_search_create",
    SEARCH_INDEX_UPDATE: "index_search_update",
    SEARCH_INDEX_DELETE: "index_search_remove",

    SOCIAL_INDEX_MANAGEMENT: "managements_social_index",
    SOCIAL_INDEX_CREATE: "social_index_create",
    SOCIAL_INDEX_UPDATE: "social_index_update",
    SOCIAL_INDEX_DELETE: "social_index_remove",

    VIRAL_INDEX_MANAGEMENT: "index_viral_view",
    VIRAL_INDEX_CREATE: "index_viral_create",
    VIRAL_INDEX_UPDATE: "index_viral_update",
    VIRAL_INDEX_DELETE: "index_viral_remove",

    NEWS_INDEX_MANAGEMENT: "index_news_view",
    NEWS_INDEX_CREATE: "index_news_create",
    NEWS_INDEX_UPDATE: "index_news_update",
    NEWS_INDEX_DELETE: "index_news_remove",

    MEDIA_MANAGEMENT: "media_view",
    MEDIA_CREATE: "media_create",
    MEDIA_UPDATE: "media_update",
    MEDIA_DELETE: "media_remove",

    STOCK_INDEX_MANAGEMENT: "index_stock_view",
    STOCK_INDEX_CREATE: "index_stock_create",
    STOCK_INDEX_UPDATE: "index_stock_update",
    STOCK_INDEX_DELETE: "index_stock_remove",

    PARTICIPATION_INDEX_MANAGEMENT: "index_participation_view",
    PARTICIPATION_INDEX_CREATE: "index_participation_create",
    PARTICIPATION_INDEX_UPDATE: "index_participation_update",
    PARTICIPATION_INDEX_DELETE: "index_participation_remove",

    BUSINESS_MEMBER_MANAGEMENT: "business_member_view",
    MEMBER_BUSINESS_CREATE: "business_member_create",
    MEMBER_BUSINESS_UPDATE: "business_member_update",
    MEMBER_BUSINESS_DELETE: "business_member_remove",

    SUB_ACCOUNT_MANAGEMENT: "managements_sub_account",
    SUB_ACCOUNT_CREATE: "sub_account_create",
    SUB_ACCOUNT_UPDATE: "sub_account_update",
    SUB_ACCOUNT_DELETE: "sub_account_remove",

    REGULAR_MEMBER_MANAGEMENT: "regular_member_view",
    MEMBER_REGULAR_CREATE: "regular_member_create",
    MEMBER_REGULAR_UPDATE: "regular_member_update",
    MEMBER_REGULAR_DELETE: "regular_member_remove",

    OFFICIAL_MEDIA_MANAGEMENT: "official_media_view",
    OFFICIAL_MEDIA_CREATE: "official_media_create",
    OFFICIAL_MEDIA_UPDATE: "official_media_update",
    OFFICIAL_MEDIA_DELETE: "official_media_remove",

    OFFICIAL_MEDIA_RANKING: "official_media_ranking_view",

    CATEGORY_QUALIFICATION_SETTINGS: "category_qualification_view",
    CATEGORY_QUALIFICATION_CREATE: "category_qualification_create",
    CATEGORY_QUALIFICATION_UPDATE: "category_qualification_update",
    CATEGORY_QUALIFICATION_DELETE: "category_qualification_remove",

    REPORT_ANALYSIS: "managements_report_analysis",
    REPORT_ANALYSIS_CREATE: "report_analysis_create",
    REPORT_ANALYSIS_UPDATE: "report_analysis_update",
    REPORT_ANALYSIS_DELETE: "report_analysis_remove",

    SYSTEM_SETTINGS: "managements_system_settings",
    SYSTEM_SETTINGS_CREATE: "system_settings_create",
    SYSTEM_SETTINGS_UPDATE: "system_settings_update",
    SYSTEM_SETTINGS_DELETE: "system_settings_remove",

    VOTE_STATISTICS: "statistics_vote_view",

    INDEX_CONFIG_UPDATE: "index_config_update",
    KEYWORD_DELETE_CONFIRM: "keyword_delete_confirm",
};

const isHavePermissionRole = (
    keyRoles: string | string[],
    permissions: { id: string | number; name: string }[] = []
): boolean => {
    const roles = typeof keyRoles === "string" ? [keyRoles] : keyRoles;
    if (keyRoles === ROLES_FOR_APP.CATEGORY_SETTING_INDEX_WEIGHT) {
        return permissions?.some((item) => roles?.includes(item.name));
    }
    if (keyRoles === ROLES_FOR_APP.KEYWORD_DELETE_CONFIRM) {
        return permissions?.some((item) => roles?.includes(item.name));
    }
    if (keyRoles === ROLES_FOR_APP.CATEGORY_UPDATE) {
        return permissions?.some((item) => roles?.includes(item.name));
    }
    if (keyRoles === ROLES_FOR_APP.STOCK_KEYWORD_UPDATE) {
        return permissions?.some((item) => roles?.includes(item.name));
    }
    if (keyRoles === ROLES_FOR_APP.TREND_INDEX_KEYWORD_UPDATE) {
        return permissions?.some((item) => roles?.includes(item.name));
    }
    // if (keyRoles === ROLES_FOR_APP.RANKING_MONITORING_UPDATE) {
    //     return permissions?.some((item) => roles?.includes(item.name));
    // }
    if (String(keyRoles).includes('_view')) {
        return permissions?.some((item) => roles?.includes(item.name));
    }
    return true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };
