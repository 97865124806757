import { IKeyword } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownCategory from 'components/Common/DropdownCategory';
import DropdownOptionCategory from 'components/Common/DropdownOptionCategory';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import TooltipHtmlCustom from 'components/Common/TooltipHtmlCustom';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { ENUM_STATUS_KEYWORD_RANKING, FREQUENCY_OPTIONS, FREQUENCY_TYPE, STATUS_KEYWORD_RANKING_OPTIONS, TYPE_SOURCE_CHART_RANKING_OPTIONS } from 'helpers/constans';
import { extractLabel, formatNumberWithCommas, getDateBasedOnTime } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from "moment";
import ModalDetailKeyword from 'pages/Keyword/ModalDetailKeyword';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import NoImage from '../../../assets/images/no-photo-available.png';
import { addConfirmKeyword, cancelConfirmKeyword, deleteKeyword, downloadExcelKeywords, getAllRankCategoriesNormal, getKeyword, getRankingMonitorSummaries, getKeywordsSearchNews as onGetKeywordsSearchNews, postKeyword, putKeyword, updateKeywordRaw } from "../../../store/thunks";
import ModalNewsSearchingKeyword from '../ModalNewsSearchingKeyword';


const typeQuery = {
  'key': StringParam,
}


const SORT_BY_DEFAULT = 'activity_index';
const ORDER_BY_DEFAULT = 'DESC';
const CATEGORY_SINGER_DEFAULT = '2';
const KEYWORD_DELETION_STATUS_DEFAULT = '1';


const RISING_NUMBER = 10;
const RISING_NUMBER_CHANGE_BACKGROUND = 40;


registerLocale("en", en);
registerLocale("ko", ko);


const ChartRankingSinger = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const TODAY_DEFAULT = `${moment().format("Y-MM-DD")}`;
  const YESTERDAY_DEFAULT = `${moment().subtract(1, 'days').format("Y-MM-DD")}`;

  const FREQUENCY_OPTIONS_LANG = FREQUENCY_OPTIONS?.filter((item) => Number(item?.value) <= 2)?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const STATUS_KEYWORD_RANKING_OPTIONS_LANG = STATUS_KEYWORD_RANKING_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));


  const TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG = TYPE_SOURCE_CHART_RANKING_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));


  const refTabTopTable = useRef<any>(null);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 500),
    sort_by: withDefault(StringParam, 'index'),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    keyword: withDefault(StringParam, ''),
    music_platform: withDefault(StringParam, ''),
    rank_category_id: withDefault(StringParam, CATEGORY_SINGER_DEFAULT),
    date: withDefault(StringParam, getDateBasedOnTime()),
    // frequency_type: withDefault(StringParam, FREQUENCY_OPTIONS_LANG[0]?.value),
    // keyword_deletion_status: withDefault(StringParam, STATUS_KEYWORD_RANKING_OPTIONS_LANG[0].value),
    // rank_diff: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.keywordsSearchNews,
      isKeywordSuccess: state.isKeywordSearchNewsSuccess,
      isKeywordLoading: state.isKeywordSearchNewsLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(KeywordProperties);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [dateSearch, setDateSearch] = useState<string>(query?.date || getDateBasedOnTime());

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_KEYWORD_RANKING_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.keyword_deletion_status))[0]);


  const [platformSearch, setPlatformSearch] = useState<Option | null>(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.music_platform)) || null);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  // const [frequencySearch, setFrequencySearch] = useState<Option | null>(
  //   FREQUENCY_OPTIONS_LANG?.filter(
  //     (item) => String(item?.value) === String(query?.frequency_type)
  //   )[0]
  // );

  const [titleTabFirst, setTitleTabFirst] = useState<string>(t('Tab Total'));
  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [idDelete, setIdDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [isOpenConfirmKeyword, setIsOpenConfirmKeyword] = useState<boolean>(false);
  const [isConfirmKeywordLoading, setIsConfirmKeywordLoading] = useState<boolean>(false);


  const [keyword, setKeyword] = useState<IKeyword | any | null>(null);

  const [keywordAnalysisNews, setKeywordAnalysisNews] = useState<{ keyword_id: number, date: string } | null>(null);

  const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<any[]>([]);
  const [categoryDropdown, setCategoryDropdown] = useState<any>({});
  const [inputKeywords, setInputKeywords] = useState<string>('');

  const [itemUpdateKeyword, setItemUpdateKeyword] = useState<IKeyword | null>(null);

  const [keywordDetail, setKeywordDetail] = useState<IKeyword | null | Object>(null);

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [keywordAction, setKeywordAction] = useState<{ status: number } | null>(null);

  const [itemUpdateKeywordRaw, setItemUpdateKeywordRaw] = useState<{ keyword_id: number, keyword: string } | null>(null);
  const [isLoadingUpdateKeywordRaw, setIsLoadingUpdateKeywordRaw] = useState<boolean>(false);


  const [summaries, setSummaries] = useState<{ full_total: number, melon_total: number, vibe_total: number, genie_total: number, bugs_total: number, flo_total: number, }>({ full_total: 0, melon_total: 0, vibe_total: 0, genie_total: 0, bugs_total: 0, flo_total: 0, });
  const [isSummariesLoading, setIsSummariesLoading] = useState<boolean>(false);

  const [queryInfoApprove, setQueryInfoApprove] = useState<{ keyword_confirm_flg: number, confirm_time: string, admin_confirm: any, } | null>(null);

  const searchData = () => {
    setOptionsSelected((prev: any) => []);
    setQuery({
      ...query,
      page: 1,
      keyword: keywordSearch || '',
      rank_category_id: categorySearch?.value || '',
      // frequency_type: frequencySearch?.value,
      date: dateSearch,
      music_platform: platformSearch?.value,
      sort_by: !platformSearch?.value ? 'index' : SORT_BY_DEFAULT,
      // keyword_deletion_status: statusSearch?.value || '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      // frequency_type: FREQUENCY_OPTIONS_LANG[0]?.value,
      date: getDateBasedOnTime(),
      keyword: '',
      rank_category_id: CATEGORY_SINGER_DEFAULT,
      // keyword_deletion_status: STATUS_KEYWORD_RANKING_OPTIONS_LANG[0].value,
      music_platform: '',
      // rank_diff: '',
      sort_by: !query?.music_platform ? 'index' : SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch(getDateBasedOnTime());
    setKeywordSearch("");
    setCategorySearch({ label: '', value: CATEGORY_SINGER_DEFAULT });
    // setFrequencySearch(FREQUENCY_OPTIONS_LANG[0]);
    // setStatusSearch(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]);
    setPlatformSearch(null);
  };

  const handleQuerySummariesData = async () => {
    try {
      setIsSummariesLoading((prev) => true);
      const res: any = await getRankingMonitorSummaries(query);
      setSummaries((prev: any) => ({
        full_total: res?.data?.full_total || 0,
        melon_total: res?.data?.melon_total || 0,
        vibe_total: res?.data?.vibe_total || 0,
        genie_total: res?.data?.genie_total || 0,
        bugs_total: res?.data?.bugs_total || 0,
        flo_total: res?.data?.flo_total || 0,
      }));
      setIsSummariesLoading((prev) => false);
    } catch (error: any) {
      setIsSummariesLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  useEffect(() => {
    dispatch(onGetKeywordsSearchNews(query));
    // if (Number(query?.page) === 1) {
    //   handleQuerySummariesData();
    // }
  }, [dispatch, query]);


  useEffect(() => {
    if (!!query?.rank_category_id && String(query?.rank_category_id) === String(categorySearch?.value)) {
      setTitleTabFirst((_prev) => t(`${extractLabel(categorySearch?.label || '')}`));
    }
  }, [JSON.stringify(categorySearch), query]);


  const changePlatform = (platform_search: any) => {
    const queryNew = {
      ...query,
      rank_diff: '',
      music_platform: platform_search?.value || '',
      sort_by: !platform_search ? 'index' : SORT_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setPlatformSearch(platform_search);
    setOptionsSelected((prev: any) => []);
  };

  const changeRankDiff = (rank_diff_search: any) => {
    const queryNew = {
      ...query,
      keyword_deletion_status: '',
      rank_diff: rank_diff_search || '',
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setOptionsSelected((prev: any) => []);
    setStatusSearch(null);
  };

  const changeLimitPerPage = (limit: number) => {
    const queryNew = {
      ...query,
      limit: limit,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
  };


  const handleUpdateKeywordRaw = async () => {
    try {
      if (!itemUpdateKeywordRaw) {
        return;
      }
      setIsLoadingUpdateKeywordRaw((_prev) => true);
      const response: any = await updateKeywordRaw(itemUpdateKeywordRaw);
      setIsLoadingUpdateKeywordRaw((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeywordRaw((_prev) => null);
        dispatch(onGetKeywordsSearchNews(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdateKeywordRaw((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywords?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.id }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywords?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 
  // Column
  const columns = useMemo(
    () => [
      // {
      //   Header: t('Key'),
      //   accessor: "",
      //   filterable: true,
      //   sortable: false,
      //   thClass: 'text-start align-middle',
      //   thWidth: 50,
      //   thComponent: () => (<>
      //     <div className="form-check" >
      //       <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
      //       <label className="form-check-label" htmlFor={`cell-check-all`}></label>
      //       {(countRowSelected > 0) && <span className="position-absolute translate-middle badge bg-primary rounded-pill"
      //         style={{ top: '-7px', left: '35%' }}
      //       >
      //         {countRowSelected || 0}
      //       </span>
      //       }
      //     </div>
      //   </>
      //   ),
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <>
      //         <div className={`${Number(item?.rank_diff) >= RISING_NUMBER_CHANGE_BACKGROUND ? 'row-bg-danger' : ''} form-check`}>
      //           <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.id })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
      //           <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
      //         </div>
      //       </>
      //     )
      //   },
      // },
      {
        Header: t('Ranking'),
        accessor: "rank",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '60px' }} className="text-center">
              <div className="text-center">{cell?.value}</div>
            </div>
          )
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <TooltipHtmlCustom html_dom={<img
              alt={item?.keyword || ''}
              loading="lazy" src={item?.image_url || NoImage}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
                backgroundColor: '#eaeaea',
                maxWidth: '700px',
                borderRadius: '8px',
              }}
            />}>
              <div className={`cursor-pointer d-flex align-items-center justify-content-between`} style={{ minWidth: "170px", maxWidth: '200px' }} onClick={(e) => {
                e.stopPropagation();
              }}>
                <div style={{ width: '42px', height: '42px', border: '1px solid rgb(230, 230, 230)', borderRadius: '4px', overflow: 'hidden' }} className="me-2 text-center">
                  <img
                    alt={item?.keyword || ''}
                    src={item?.image_url || NoImage}
                    style={{
                      width: 'auto',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                      // backgroundColor: '#eaeaea'
                    }}
                  />
                </div>
                <div style={{ width: '140px' }}>{cell?.value}</div>
              </div >
            </TooltipHtmlCustom>
          )
        },
      },
      ...(!query?.music_platform ? [{
        Header: t("Song count"),
        accessor: "song_count",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      }] : []),
      ...(!query?.music_platform ? [{
        Header: t("Popular Index"),
        accessor: "index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      }] : []),
      {
        Header: t("Activity Index"),
        accessor: "activity_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      ...(!query?.music_platform ? [{
        Header: t("P Index"),
        accessor: "participation_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "align-middle text-end",
        description: t("Participation Index"),
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      }] : []),
      {
        Header: t(""),
        accessor: "__",
        filterable: false,
        sortable: true,
        thWidth: 80,
        thClass: "align-middle text-start",
        Cell: (cell: any) => (
          <div className="text-start pe-3" style={{ minWidth: "120px" }}>

          </div>
        ),
      },
      ...(query?.music_platform ? ([{
        Header: t("Song"),
        accessor: "song_title",
        filterable: false,
        sortable: true,
        thWidth: 200,
        thClass: "align-middle text-start",
        Cell: (cell: any) => (
          <div className="text-start pe-3" style={{ minWidth: "140px" }}>
            {`${cell?.value || ''}`}
          </div>
        ),
      },
      {
        Header: t("Artist"),
        accessor: "authors",
        filterable: false,
        sortable: true,
        thWidth: 200,
        thClass: "align-middle text-start",
        Cell: (cell: any) => (
          <div className="text-start pe-3" style={{ minWidth: "140px" }}>
            {`${(cell?.value || [])?.map((au: any) => `${au?.name}`).join((', '))}`}
          </div>
        ),
      },
      {
        Header: t(""),
        accessor: "_",
        filterable: false,
        sortable: true,
        thWidth: 60,
        thClass: "align-middle text-start",
        Cell: (cell: any) => (
          <div className="text-start pe-3" style={{ minWidth: "60px" }}>

          </div>
        ),
      }
      ]) : []),

    ],
    [i18n?.language, JSON.stringify(optionsSelected), JSON.stringify(keywords?.list), userPermissions, JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangePicker = (value: any) => {
    setDateSearch((_prev) => value ? moment(value).format("Y-MM-DD") : '');
  }

  const handleChangeDatePicker = (date: any) => {
    handleChangePicker(date);
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IKeyword) => {
    setIdDelete((_prev) => item?.id);
  };

  const handleActionDelete = async () => {
    if (!idDelete) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteKeyword(idDelete);
      if (response?.code === 200) {
        dispatch(onGetKeywordsSearchNews(query));
        (Number(query?.page) !== 1) && handleQuerySummariesData();
        setIsConfirmLoading((_prev) => false);
        setIdDelete((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdDelete((_prev) => null);
  }
  // End::Delete


  // Begin::Confirm
  const handleConfirmKeyword = () => {
    setIsOpenConfirmKeyword((_prev) => true);
  };

  const handleActionConfirmKeyword = async () => {
    try {
      setIsConfirmKeywordLoading((_prev) => true);
      const dataForm = {
        date: query?.date,
        frequency_type: query?.frequency_type
      };
      const response: any = !!Number(keywords?.keyword_confirm_flg) ? await cancelConfirmKeyword(dataForm) : await addConfirmKeyword(dataForm);
      if (response?.code === 200) {
        dispatch(onGetKeywordsSearchNews(query));
        setIsConfirmKeywordLoading((_prev) => false);
        setIsOpenConfirmKeyword((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmKeywordLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmKeywordLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmKeywordClick = () => {
    setIsOpenConfirmKeyword((_prev) => false);
  }
  // End::Confirm



  // Begin::Update status
  const handleConfirmChangeStatus = (item: { status: number }) => {
    setKeywordAction((_prev) => item);
  };

  const handleActionChangeStatus = async () => {
    // if (!keywordAction) { return; };
    // try {
    //   setIsConfirmLoading((_prev) => true);
    //   const kw_s = (optionsSelected || []).flat()?.map((item: any) => item?.value);
    //   const response: any = await changeStatusKeyword({
    //     ...keywordAction,
    //     keyword_ids: kw_s,
    //     frequency_type: frequencySearch?.value,
    //     date: dateSearch,
    //   });
    //   if (response?.code === 200) {
    //     dispatch(onGetKeywordsSearchNews(query));
    //     (Number(query?.page) !== 1) && handleQuerySummariesData();
    //     setIsConfirmLoading((_prev) => false);
    //     setKeywordAction((_prev) => null);
    //     setOptionsSelected((prev: any) => []);
    //     toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
    //   } else {
    //     setIsConfirmLoading((_prev) => false);
    //     toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
    //   }
    // } catch (error: any) {
    //   setIsConfirmLoading((_prev) => false);
    //   toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
    //   return error;
    // }
  };

  const onCloseConfirmChangeStatusClick = () => {
    setKeywordAction((_prev) => null);
  }
  // End::Update status

  const handleUpdateKeyword = async () => {
    try {
      if (!itemUpdateKeyword) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putKeyword(itemUpdateKeyword?.id, { keyword: itemUpdateKeyword?.keyword });
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeyword((_prev) => null);
        dispatch(onGetKeywordsSearchNews(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleActionAddKeyword = async () => {
    try {
      setIsLoading((_prev) => true);
      const dataForm = {
        category_id: categoryDropdown?.level_5?.value || categoryDropdown?.level_4?.value || categoryDropdown?.level_3?.value || categoryDropdown?.level_2?.value || categoryDropdown?.level_1?.value,
        keywords: String(inputKeywords || "")
          .split("\n")
          ?.reduce((arr: string[], item: string) => {
            return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
          }, [])
      };
      const response: any = await postKeyword(dataForm);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setKeyword((prev: any) => null);
        setCategoryDropdown((prev: any) => ({}));
        setInputKeywords((prev: any) => '');
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        dispatch(onGetKeywordsSearchNews(query));
        (Number(query?.page) !== 1) && handleQuerySummariesData();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleCallKeywordDetail = async (item: IKeyword) => {
    try {
      setKeywordDetail(() => ({ keyword_type: item?.keyword_type }));
      const response: any = await getKeyword(item?.id);
      if (response?.code === 200) {
        setKeywordDetail(() => (response?.data));
      } else {
        setKeywordDetail(() => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setKeywordDetail(() => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseKeywordDetailClick = () => {
    setKeywordDetail(() => null);
  };

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelKeywords({ ...query, page: 1 });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'keywords';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const onHideKeywordAnalysisNews = () => {
    setKeywordAnalysisNews((_prev) => null);
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {

        const [res1]: any = await Promise.all([getAllRankCategoriesNormal({})]);
        const depth1 = res1?.data?.find((c: any) => Number(c?.depth) === 1);
        const depth2 = res1?.data?.filter((c: any) => Number(c?.depth) === 2) || [];
        setOptionCategoryDropdown((_prev: any) => ([
          {
            ...depth1,
            category_id: `${depth1?.id}`,
            subs: depth2?.map((c: any) => ({ ...c, category_id: `${c?.id}` }))
          }
        ])); // res1?.data || 
        if (query?.rank_category_id) {
          setCategorySearch((_prev) => {
            return ({
              label: '',
              value: `${query?.rank_category_id}`,
            });
          });
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!query?.rank_category_id) {
      setCategorySearch({ label: t('All Category'), value: '' });
    }
    document.title = `${t('Chart Ranking Singer')} - ${t('Chart Ranking Management')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Chart Ranking Singer')} pageTitle={t('Chart Ranking Management')} />
          {isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_SINGER, userPermissions) && (<>
            {/* <div className="d-flex justify-content-end gap-3 align-items-center text-end my-2 ">
              <div className="d-flex justify-content-end align-items-center">
                <div className="me-1">
                  {t('Showcase')}
                </div>
                <ul className="pagination pagination-separated" role="navigation" >
                  <li className={`page-item ${String(query?.limit) === '50' ? 'active' : ''}`}>
                    <a rel="prev" role="button" href="/" className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLimitPerPage(50);
                      }}
                    >50</a>
                  </li>
                  <li className={`page-item ${String(query?.limit) === '100' ? 'active' : ''}`}>
                    <a rel="canonical" role="button" href="/" className="page-link" aria-current="page"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLimitPerPage(100);
                      }}
                    >100</a>
                  </li>
                  <li className={`page-item ${String(query?.limit) === '200' ? 'active' : ''}`}>
                    <a rel="next" role="button" href="/" className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLimitPerPage(200);
                      }}
                    >200</a>
                  </li>
                </ul>
              </div>
              <div className="d-flex ms-3">
                <div className="me-2">{t('Page Auto-Refresh')}</div>
                <AutoRefreshComponent refresher={searchData} />
              </div>
            </div> */}
            <Row>
              <Col sm={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-12 col-sm-6 col-md-6">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="fs-12">{t('Weight')}: <strong className="text-primary">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? t('Updated') : t('Pending')}</strong></div>
                            <div className="fs-12">{t('Editor')}: <span className="text-muted">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? (queryInfoApprove?.admin_confirm?.email || '') : '-'}</span></div>
                            <div className="fs-12">{t('Time')}: <span className="text-muted">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? (queryInfoApprove?.confirm_time || '') : '-'}</span></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 text-end">
                        <button
                          type="button"
                          className="btn btn-info ms-3"
                          onClick={handleConfirmKeyword}
                          disabled={true}
                        >
                          <span className="ms-1">{t('Update Weight')}</span>
                        </button>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Col md={12} xl={12}>
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Tab Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={keywords?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Tab Melon')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.melon_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Tab VIBE')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.vibe_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Tab Genie')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.genie_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Tab Bugs')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.bugs_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Tab FLO')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summaries?.flo_total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={3} className='mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Category')} isShow={!!categorySearch}>
                          <DropdownCategory
                            name="category"
                            placeholder={t('Category')}
                            isClearable={true}
                            dataList={optionCategoryDropdown || []}
                            initialValue={categorySearch ?? undefined}
                            onChangeCategory={(event) => {
                              setCategorySearch(event)
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={3} xxl={2} className='date-picker-wrapper-custom z-hight mt-3 mt-md-3 mt-xl-2'>
                        <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                          <RangeDatePicker
                            maxDate={moment().toDate()}
                            frequencyTypeSearch={null}
                            dateSearch={dateSearch}
                            handleChangeDatePicker={handleChangeDatePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={4} xxl={5} className="hstack gap-1 justify-content-center justify-content-md-end justify-content-xxl-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isKeywordLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-1 sticky-menu-tab-top-table" ref={refTabTopTable}>
                    <Row>
                      <Col sm={12} md={12} xl={12} className="my-2">
                        <ul className="nav nav-tabs nav-tabs-custom nav-primary nav-justified nav nav-tabs" style={{ maxWidth: '500px', borderBottom: '0px solid #fff' }}>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${!query?.rank_diff && String(platformSearch?.value || '') === '' ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changePlatform(null);
                            }}
                          >{t('Tab Total')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(platformSearch?.value || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[0]?.value) ? 'active' : ''}`} role="button" href="/" onClick={(e: any) => {
                            e.preventDefault();
                            changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[0]);
                          }}
                          >{t('Tab Melon')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(platformSearch?.value || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[1]?.value) ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[1]);
                            }}
                          >{t('Tab VIBE')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(platformSearch?.value || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[2]?.value) ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[2]);
                            }}
                          >{t('Tab Genie')}</a></li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(platformSearch?.value || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[3]?.value) ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[3]);
                            }}
                          >{t('Tab Bugs')}</a>
                          </li>
                          <li className="nav-item"><a className={`nav-link cursor-pointer ${String(platformSearch?.value || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[4]?.value) ? 'active' : ''}`} role="button" href="/"
                            onClick={(e: any) => {
                              e.preventDefault();
                              changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[4]);
                            }}
                          >{t('Tab FLO')}</a>
                          </li>
                        </ul>
                      </Col>
                      {/* <Col sm={12} md={6} xl={6} className="my-2 text-end ">
                        {isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions) && <div className="px-3">
                          {(String(statusSearch?.value) !== String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[2]?.value)) && <button
                            type="button"
                            className="btn btn-soft-warning ms-2 "
                            style={{ width: '90px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.HIDDEN }) }}
                          >
                            {t('Hide')}
                          </button>}
                          {(String(statusSearch?.value) !== String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[1]?.value)) && <button
                            type="button"
                            className="btn btn-soft-danger ms-2 fs-14"
                            style={{ width: '90px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.EXCLUDED }) }}
                          >
                            {t('Exclude')}
                          </button>}
                          {(String(statusSearch?.value) !== String(STATUS_KEYWORD_RANKING_OPTIONS_LANG[0]?.value)) && <button
                            type="button"
                            className="btn btn-soft-success ms-2 fs-14"
                            style={{ width: '90px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.ACTIVATED }) }}
                          >
                            {t('Activate')}
                          </button>}
                        </div>}
                      </Col> */}
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed sticky-table-chart-ranking-singer"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={keywords?.list?.length ? keywords?.list : []}
                      textNoData={''}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywords?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywords?.pagination?.total) / Number(keywords?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordLoading}
                      plusPaddingTopFixed={window.innerWidth >= 1000 ? refTabTopTable?.current?.clientHeight : 0}
                      isShowPagination={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>)}
          <ToastContainer closeButton={false} limit={1} />
        </Container>

        <Modal
          isOpen={keyword !== null}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={() => setKeyword(null)}>
            {t('Button Create Keyword')}
          </ModalHeader>
          <ModalBody className="pt-0">
            <div>
              <Row>
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Category')}
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 1')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={optionCategoryDropdown || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_1 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_1: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 2')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_1?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_2 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_2: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 3')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_2?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_3 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_3: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 4')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_3?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_4 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({
                        ...prev,
                        level_4: e,
                      }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
                <Col sm={6} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  <LabelWrapper label={t('Level 5')} isShow={true}>
                    <DropdownOptionCategory
                      name=""
                      dataList={categoryDropdown?.level_4?.subs || [{ label: t('-----'), value: '' }]}
                      placeholder={`${t("Ad Platform")}...`}
                      className="search-filter-category-type dropdown-status-rounded"
                      classNamePrefix="name-prefix"
                      initialValue={categoryDropdown?.level_5 || null}
                      onChangeSelect={(e: any) => setCategoryDropdown((prev: any) => ({ ...prev, level_5: e }))}
                      isHasOptionAll={true}
                      optionAll={{ label: t('-----'), value: '' }}
                    />
                  </LabelWrapper>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={2} lg={2} className='mt-3 mt-sm-3 mt-md-2'>
                  {t('Keyword')}
                </Col>
                <Col sm={12} md={10} lg={10}>
                  <Input
                    type="textarea"
                    rows={10}
                    id="keywords"
                    name="keywords"
                    autocomplete={false}
                    value={inputKeywords}
                    placeholder={`${t("Keyword")}...`}
                    onChange={(event: any) => {
                      setInputKeywords((prev: any) => (event?.target?.value || ''))
                    }}
                  />
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-center mt-3 ">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14"
                  type="button"
                  color="light"
                  onClick={() => { !isLoading && setKeyword(null) }}
                  style={{ minWidth: '100px' }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className={`btn rounded-pill btn-primary fs-14`}
                  type="button"
                  disabled={isLoading || (!categoryDropdown?.level_1?.value || !inputKeywords)}
                  onClick={handleActionAddKeyword}
                  style={{ minWidth: '100px' }}
                >
                  {isLoading ? (
                    <Spinner size="sm" ></Spinner>
                  ) : t("Button Update")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={itemUpdateKeyword !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateKeyword((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateKeyword((_prev) => null)}>
            {t('Button Update Keyword')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Keyword")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="keyword"
                  name="keyword"
                  autocomplete={false}
                  value={itemUpdateKeyword?.keyword}
                  placeholder={`${t("Keyword")}...`}
                  onChange={(event: any) => {
                    setItemUpdateKeyword((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  style={{ width: '100px' }}
                  onClick={() => { !isLoading && setItemUpdateKeyword((_prev) => null) }}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoading}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateKeyword()}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!keywordAnalysisNews}
          centered={true}
          toggle={onHideKeywordAnalysisNews}
          size="xl"
          className="modal-fullscreen"
          scrollable={true}
          keyboard={true}
        >
          <ModalHeader toggle={onHideKeywordAnalysisNews}>
            {t('News Searching Keyword')}
          </ModalHeader>
          <ModalBody className="pt-3" style={{ minHeight: "450px" }}>
            <ModalNewsSearchingKeyword keywordAnalysisNews={keywordAnalysisNews} />
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Keyword')}
          isOpen={idDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <ModalDetailKeyword
          header={t("Keyword Description")}
          isShow={!!keywordDetail}
          keyword={keywordDetail}
          onClose={onCloseKeywordDetailClick}
        />
        <ModalConfirm
          header={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('Hide') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('Activate') : t('Exclude'))}
          textButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('Hide') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('Activate') : t('Exclude'))}
          classButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? 'btn-warning' : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? 'btn-success' : 'btn-danger')}
          title={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? `${t('Do you want to hide these keywords on the date [DATE]').replace('[DATE]', query?.date || '')}?` : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? `${t('Are you sure you want to activate this')}?` : `${t('Are you sure you want to exclude this keyword')}?`)}
          content={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('When hidden, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('After confirming the activate action, the data will be permanently activated and cannot be recovered. Do you want to proceed with the activate action.') : t('When excluded, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.'))}
          isOpen={keywordAction !== null}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmChangeStatusClick}
          onConfirm={handleActionChangeStatus}
        />
        <ModalConfirm
          header={!!Number(keywords?.keyword_confirm_flg) ? t('Unconfirm') : t('Approve')}
          textButtonConfirm={!!Number(keywords?.keyword_confirm_flg) ? t('Unconfirm') : t('Approve')}
          classButtonConfirm={'btn-soft-info'}
          title={!!Number(keywords?.keyword_confirm_flg) ? `${t('Are you sure you want to unconfirm this keywords')}?` : <div dangerouslySetInnerHTML={{ __html: `${t('Do you want to approve the [INTERVAL] rankings for [DATE]').replace('[DATE]', `<span class='d-inline-block'>${query?.date || ''}</span>`).replace('[INTERVAL]', query?.frequency_type === String(FREQUENCY_TYPE.DAILY) ? t('daily') : (query?.frequency_type === String(FREQUENCY_TYPE.WEEKLY) ? t('weekly') : ''))}?` }} />}
          content={!!Number(keywords?.keyword_confirm_flg) ? t('When unconfirmed, it will hide the keyword from the rankings, and the changes will be reflected right now.') : t('Upon approval, the ranking data will be published.')}
          isOpen={isOpenConfirmKeyword}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmKeywordLoading}
          onClose={onCloseConfirmKeywordClick}
          onConfirm={handleActionConfirmKeyword}
        />
        <Modal
          isOpen={itemUpdateKeywordRaw !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateKeywordRaw((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateKeywordRaw((_prev) => null)}>
            {t('Button Update')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Keyword")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="KeywordRaw"
                  name="KeywordRaw"
                  autocomplete={false}
                  value={itemUpdateKeywordRaw?.keyword}
                  placeholder={`${t("Keyword")}...`}
                  onChange={(event: any) => {
                    setItemUpdateKeywordRaw((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  disabled={isLoadingUpdateKeywordRaw}
                  style={{ width: '100px' }}
                  onClick={() => setItemUpdateKeywordRaw((_prev) => null)}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoadingUpdateKeywordRaw}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateKeywordRaw()}
                >
                  {isLoadingUpdateKeywordRaw ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment >
  );
};

export default ChartRankingSinger;

