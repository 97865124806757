import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/allRoutes';

const SearchOptionPages = () => {
    const { t } = useTranslation();
    const { userPermissions } = useRole();
    const [value, setValue] = useState("");
    const onChangeData = (value: any) => {
        setValue(value);
    };

    const menuItems: any = [
        {
            label: t('Dashboards'),
            link: ROUTES.DASHBOARD,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD, userPermissions),
        },
        {
            label: t('Trend Index Category Management'),
            link: ROUTES.TREND_INDEX_CATEGORY_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_MANAGEMENT, userPermissions),
        },
        {
            label: t('Stock Category Management'),
            link: ROUTES.STOCK_CATEGORY_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_MANAGEMENT, userPermissions),
        },
        {
            label: t('Trend Index Keyword Management'),
            link: ROUTES.TREND_INDEX_KEYWORD_MANAGEMENT,
            icon: <i className=" ri-a-b align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_MANAGEMENT, userPermissions),
        },
        {
            label: t('Stock Keyword Management'),
            link: ROUTES.STOCK_KEYWORD_MANAGEMENT,
            icon: <i className=" ri-a-b align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_MANAGEMENT, userPermissions),
        },
        {
            label: t('Ranking Monitoring'),
            link: ROUTES.RANKING_MONITORING,
            icon: <i className=" ri-bar-chart-horizontal-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING, userPermissions),
        },
        {
            label: t('Collecting Monitoring'),
            link: ROUTES.COLLECTING_MONITORING,
            icon: <i className=" ri-bar-chart-horizontal-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.COLLECTING_MONITORING, userPermissions),
        },
        {
            label: t('Chart Ranking Singer'),
            link: ROUTES.CHART_RANKING_SINGER,
            icon: <i className=" ri-bar-chart-horizontal-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_SINGER, userPermissions),
        },
        {
            label: t('Search Index Management'),
            link: ROUTES.SEARCH_INDEX_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.SEARCH_INDEX_MANAGEMENT, userPermissions),
        },
        {
            label: t('Viral Index Management'),
            link: ROUTES.VIRAL_INDEX_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.VIRAL_INDEX_MANAGEMENT, userPermissions),
        },
        {
            label: t('News Index Management'),
            link: ROUTES.NEWS_INDEX_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_INDEX_MANAGEMENT, userPermissions),
        },
        {
            label: t('Stock Index Management'),
            link: ROUTES.STOCK_INDEX_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.STOCK_INDEX_MANAGEMENT, userPermissions),
        },
        {
            label: t('Participation Index Management'),
            link: ROUTES.PARTICIPATION_INDEX_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_INDEX_MANAGEMENT, userPermissions),
        },
        {
            label: t('Management Participation Templates'),
            link: ROUTES.PARTICIPATION_TEMPLATE_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TEMPLATE_MANAGEMENT, userPermissions),
        },
        {
            label: t('Management Participation Categories'),
            link: ROUTES.PARTICIPATION_CATEGORY_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_CATEGORY_MANAGEMENT, userPermissions),
        },
        {
            label: t('Management Participation Tickets'),
            link: ROUTES.PARTICIPATION_TICKET_MANAGEMENT,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TICKET_MANAGEMENT, userPermissions),
        },
        {
            label: t('Manage Official Media'),
            link: ROUTES.OFFICIAL_MEDIA_MANAGEMENT,
            icon: <i className=" ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_MANAGEMENT, userPermissions),
        },
        {
            label: t('Official Media Ranking'),
            link: ROUTES.OFFICIAL_MEDIA_RANKING,
            icon: <i className=" ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_RANKING, userPermissions),
        },
        {
            label: t('Category Qualification Settings'),
            link: ROUTES.CATEGORY_QUALIFICATION_SETTINGS,
            icon: <i className=" ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_QUALIFICATION_SETTINGS, userPermissions),
        },
        {
            label: t('Vote Statistics'),
            link: ROUTES.VOTE_STATISTICS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.VOTE_STATISTICS, userPermissions),
        },
        {
            label: t('Payment History'),
            link: ROUTES.PAYMENT_HISTORY,
            icon: <i className=" ri-wallet-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.PAYMENT_HISTORY, userPermissions),
        },
        {
            label: t('Subscription Management'),
            link: ROUTES.SUBSCRIPTION_MANAGEMENT,
            icon: <i className=" ri-wallet-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.SUBSCRIPTION_MANAGEMENT, userPermissions),
        },
        {
            label: t('Regular Member Management'),
            link: ROUTES.REGULAR_MEMBER_MANAGEMENT,
            icon: <i className=" ri-user-heart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REGULAR_MEMBER_MANAGEMENT, userPermissions),
        },
        {
            label: t('Business Member Management'),
            link: ROUTES.BUSINESS_MEMBER_MANAGEMENT,
            icon: <i className=" ri-user-star-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.BUSINESS_MEMBER_MANAGEMENT, userPermissions),
        },
        {
            label: t('Media Management'),
            link: ROUTES.MEDIA_MANAGEMENT,
            icon: <i className=" ri-building-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_MANAGEMENT, userPermissions),
        },
        {
            label: t('Operator'),
            link: ROUTES.OPERATOR_LIST,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.OPERATOR_LIST, userPermissions),
        },
        {
            label: t('Role'),
            link: ROUTES.USER_ROLE,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions),
        },
        {
            label: t('Activity Logs'),
            link: ROUTES.LOG_LIST,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.LOG_LIST, userPermissions),
        },
        {
            label: t('Reports & Analysis'),
            link: ROUTES.REPORT_ANALYSIS,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_ANALYSIS, userPermissions),
        },
        {
            label: t('Logs'),
            link: ROUTES.INDEX_LOG,
            icon: <i className=" ri-time-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.INDEX_LOG, userPermissions),
        },
        {
            label: t('General Setting'),
            link: ROUTES.GENERAL_SETTING,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.GENERAL_SETTING, userPermissions),
        },
        {
            label: t('Index Setting'),
            link: ROUTES.INDEX_SETTING,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.INDEX_SETTING, userPermissions),
        },
    ];

    useEffect(() => {
        var searchOptions: any = document.getElementById("search-close-options");
        var dropdown: any = document.getElementById("search-dropdown");
        var searchInput: any = document.getElementById("search-options");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
        });

        document.body.addEventListener("click", function (e: any) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });
    }, []);

    const checkIsIncludeText = (text: string, search: string) => {
        return String(text).toUpperCase().includes(String(search).toUpperCase());
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-sm-block">
                <div className="position-relative">
                    <Input type="text" className="form-control" placeholder={`${t('Button Search')}...`}
                        id="search-options"
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                            onChangeData(e.target.value);
                        }} />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar style={{ height: "320px" }}>

                        <div className="dropdown-header mt-2">
                            <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('Pages')}</h6>
                        </div>

                        {menuItems?.map((item: any, index: number) => (
                            <Link key={index} to={item?.link} className={`dropdown-item notify-item ${(!!item?.isShow && checkIsIncludeText(item?.label || '', value || '')) ? '' : 'd-none'}`}>
                                {item?.icon}
                                <span className="">{item?.label}</span>
                            </Link>
                        ))}
                    </SimpleBar>
                </div>
            </form>
        </React.Fragment>
    );
};

export default SearchOptionPages;