import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";
import Settings from "../pages/Pages/Profile/Settings/Settings";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile

import Profile from "pages/OperatorManagement/Profile";
import UserProfile from "../pages/Authentication/user-profile";

import RoleList from "pages/OperatorManagement/Role";
import OperatorList from "pages/OperatorManagement/Operator";

import TrendIndexKeywordManagement from "pages/Keyword/TrendIndexKeyword";
import StockKeywordManagement from "pages/Keyword/StockKeyword";
import ParticipationManagement from "pages/Participation";
import TrendIndexSetting from "pages/TrendIndex/TrendIndexSetting";
import ReportAnalysis from "pages/ReportAnalysis";
import CategoryList from "pages/Category/CategoryList";
import SearchIndexManagement from "pages/TrendIndex/SearchIndexManagement";
import ViralIndexManagement from "pages/TrendIndex/ViralIndexManagement";
import SocialIndexManagement from "pages/TrendIndex/SocialIndexManagement";
import IndexLog from "pages/Logging/IndexLog";
import IndexSetting from "pages/SystemSettings/IndexSetting";
import GeneralSetting from "pages/SystemSettings/GeneralSetting";
import LogList from "pages/OperatorManagement/LogList";
import MediaManagement from "pages/Media";
import RankingMonitoring from "pages/Monitoring/RankingMonitoring";
import ManageOfficialMedia from "pages/OfficialMediaManagement/ManageOfficialMedia";
import CategoryQualificationSetting from "pages/OfficialMediaManagement/CategoryQualificationSetting";
import RegularMemberManagement from "pages/Member/Regular";
import BusinessMemberManagement from "pages/Member/Business";
import OfficialMediaRanking from "pages/OfficialMediaManagement/OfficialMediaRanking";
import CollectingMonitoring from "pages/Monitoring/CollectingMonitoring";
import { CATEGORY_TYPE } from "helpers/constans";
import StockIndexManagement from "pages/TrendIndex/StockIndexManagement";
import NewsIndexManagement from "pages/TrendIndex/NewsIndexManagement";
import ParticipationTemplateManagement from "pages/Participation/Template";
import ParticipationCategoryManagement from "pages/Participation/Category";
import ParticipationIndexManagement from "pages/TrendIndex/ParticipationIndexManagement";
import ParticipationTicketManagement from "pages/Participation/Ticket";
import PaymentManagement from "pages/PaymentManagement";
import PaymentHistory from "pages/PaymentManagement/PaymentHistory";
import VoteStatistics from "pages/Statistics/VoteStatistics";
import SubscriptionManagement from "pages/PaymentManagement/Subscription";
import SubAccountManagement from "pages/Member/SubAccount";
import ChartRankingSinger from "pages/ChartRanking/Singer";


const ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",

  DASHBOARD: "/dashboard",

  PROFILE: "/profile",
  PROFILE_SETTING: "/pages-profile-settings",

  OPERATOR_LIST: "/operator-management/operator",
  USER_ROLE: "/operator-management/role",
  USER_PROFILE: "/operator-management/profile",
  LOG_LIST: "/operator-management/activity-logs",

  CATEGORY_MANAGEMENT: "/category-management",
  STOCK_CATEGORY_MANAGEMENT: "/stock-category-management",
  TREND_INDEX_CATEGORY_MANAGEMENT: "/trend-index-category-management",
  CATEGORY_TREE: "/category-management/tree",

  KEYWORD_MANAGEMENT: '/keyword-management',
  TREND_INDEX_KEYWORD_MANAGEMENT: '/trend-index-keyword-management',
  STOCK_KEYWORD_MANAGEMENT: '/stock-keyword-management',
  MEDIA_MANAGEMENT: '/media-management',
  PARTICIPATION_MANAGEMENT: '/participation-management',
  PARTICIPATION_TEMPLATE_MANAGEMENT: '/participation-template-management',
  PARTICIPATION_CATEGORY_MANAGEMENT: '/participation-category-management',
  PARTICIPATION_TICKET_MANAGEMENT: '/participation-ticket-management',
  TREND_INDEX_MANAGEMENT: '/trend-index-management',
  TREND_INDEX_SETTINGS_MANAGEMENT: '/trend-index-settings',
  SEARCH_INDEX_MANAGEMENT: '/search-index-management',
  SOCIAL_INDEX_MANAGEMENT: '/social-index-management',
  VIRAL_INDEX_MANAGEMENT: '/viral-index-management',
  NEWS_INDEX_MANAGEMENT: '/news-index-management',
  STOCK_INDEX_MANAGEMENT: '/stock-index-management',
  PARTICIPATION_INDEX_MANAGEMENT: '/participation-index-management',
  INVESTMENT_INDEX_MANAGEMENT: '/investment-index-management',
  USER_MANAGEMENT: '/user-management',
  REPORT_ANALYSIS: '/report-analysis-management',
  SYSTEM_SETTINGS: '/system-settings',
  PAYMENT_MANAGEMENT: '/payment-management',
  PAYMENT_HISTORY: '/payment-history',
  SUBSCRIPTION_MANAGEMENT: '/subscription-management',

  RANKING_MONITORING: "/monitoring/ranking-monitoring",
  COLLECTING_MONITORING: "/monitoring/collecting-monitoring",

  CHART_RANKING_SINGER: "/chart-ranking/singer",

  REGULAR_MEMBER_MANAGEMENT: "/member/regular-member",
  BUSINESS_MEMBER_MANAGEMENT: "/member/business-member",
  SUB_ACCOUNT_MANAGEMENT: "/account/sub-account",

  OFFICIAL_MEDIA_MANAGEMENT: "/official-media/manage-official-media",
  OFFICIAL_MEDIA_RANKING: "/official-media/manage-official-ranking",
  CATEGORY_QUALIFICATION_SETTINGS:
    "/official-media/category-qualification-settings",

  CONFIGURATION: "/configuration",

  INDEX_SETTING: "/system-settings/index-setting",
  GENERAL_SETTING: "/system-settings/general-setting",

  LOG: "/log",
  LOG_API: "/log/log-api",
  INDEX_LOG: "/log/index-log",

  STATISTICS: "/statistics",
  VOTE_STATISTICS: "/statistics/vote",
};

const ROUTE_NAMES = [
  {
    link: ROUTES.LOGIN,
    name: "LogIn",
  },
  // {
  //   link: ROUTES.LOGOUT,
  //   name: 'Log Out'
  // },
  {
    link: ROUTES.DASHBOARD,
    name: "Dashboards",
  },
  // {
  //   link: ROUTES.PROFILE,
  //   name: 'Profile'
  // },
  // {
  //   link: ROUTES.PROFILE_SETTING,
  //   name: 'Profile Setting'
  // },
  // {
  //   link: ROUTES.USER_PROFILE,
  //   name: 'User Profile'
  // },
  {
    link: ROUTES.OPERATOR_LIST,
    name: "Operator",
  },
  {
    link: ROUTES.USER_ROLE,
    name: "Role",
  },
  {
    link: ROUTES.TREND_INDEX_CATEGORY_MANAGEMENT,
    name: "Trend Index Category Management",
  },
  {
    link: ROUTES.STOCK_CATEGORY_MANAGEMENT,
    name: "Stock Category Management",
  },
  // {
  //   link: ROUTES.CATEGORY_TREE,
  //   name: 'Category Tree'
  // },
  {
    link: ROUTES.TREND_INDEX_KEYWORD_MANAGEMENT,
    name: "Trend Index Keyword Management",
  },
  {
    link: ROUTES.STOCK_KEYWORD_MANAGEMENT,
    name: "Stock Keyword Management",
  },
  {
    link: ROUTES.REGULAR_MEMBER_MANAGEMENT,
    name: "Regular Member Management",
  },
  {
    link: ROUTES.BUSINESS_MEMBER_MANAGEMENT,
    name: "Business Member Management",
  },
  {
    link: ROUTES.SUB_ACCOUNT_MANAGEMENT,
    name: "Sub-account Management",
  },
  {
    link: ROUTES.MEDIA_MANAGEMENT,
    name: "Media Management",
  },
  {
    link: ROUTES.PARTICIPATION_MANAGEMENT,
    name: "Participation Management",
  },
  {
    link: ROUTES.PARTICIPATION_TEMPLATE_MANAGEMENT,
    name: "Management Participation Templates",
  },
  {
    link: ROUTES.PARTICIPATION_CATEGORY_MANAGEMENT,
    name: "Management Participation Categories",
  },
  {
    link: ROUTES.PARTICIPATION_TICKET_MANAGEMENT,
    name: "Management Participation Tickets",
  },
  {
    link: ROUTES.PAYMENT_MANAGEMENT,
    name: 'Payment Management'
  },
  {
    link: ROUTES.PAYMENT_HISTORY,
    name: 'Payment History'
  },
  {
    link: ROUTES.SUBSCRIPTION_MANAGEMENT,
    name: 'Subscription Management'
  },
  {
    link: ROUTES.TREND_INDEX_MANAGEMENT,
    name: "Trend Index Management",
  },
  {
    link: ROUTES.TREND_INDEX_SETTINGS_MANAGEMENT,
    name: "Trend Index Settings",
  },
  {
    link: ROUTES.SEARCH_INDEX_MANAGEMENT,
    name: "Search Index Management",
  },
  {
    link: ROUTES.SOCIAL_INDEX_MANAGEMENT,
    name: "Social Index Management",
  },
  {
    link: ROUTES.VIRAL_INDEX_MANAGEMENT,
    name: "Viral Index Management",
  },
  {
    link: ROUTES.NEWS_INDEX_MANAGEMENT,
    name: "News Index Management",
  },
  {
    link: ROUTES.STOCK_INDEX_MANAGEMENT,
    name: "Stock Index Management",
  },
  {
    link: ROUTES.PARTICIPATION_INDEX_MANAGEMENT,
    name: "Participation Index Management",
  },
  // {
  //   link: ROUTES.USER_MANAGEMENT,
  //   name: 'User Management'
  // },
  {
    link: ROUTES.REPORT_ANALYSIS,
    name: "Reports & Analysis",
  },
  {
    link: ROUTES.SYSTEM_SETTINGS,
    name: "System Settings",
  },
  {
    link: ROUTES.RANKING_MONITORING,
    name: "Ranking Monitoring",
  },
  {
    link: ROUTES.COLLECTING_MONITORING,
    name: "Collecting Monitoring",
  },
  {
    link: ROUTES.CHART_RANKING_SINGER,
    name: "Chart Ranking Singer",
  },
  {
    link: ROUTES.OFFICIAL_MEDIA_MANAGEMENT,
    name: "Manage Official Media",
  },
  {
    link: ROUTES.OFFICIAL_MEDIA_RANKING,
    name: "Official Media Ranking",
  },
  {
    link: ROUTES.CATEGORY_QUALIFICATION_SETTINGS,
    name: "Category Qualification Settings",
  },
  // {
  //   link: ROUTES.CONFIGURATION,
  //   name: 'Configuration'
  // },
  {
    link: ROUTES.INDEX_SETTING,
    name: "Index Setting",
  },
  {
    link: ROUTES.GENERAL_SETTING,
    name: "General Setting",
  },
  // {
  //   link: ROUTES.LOG,
  //   name: 'Log'
  // },
  {
    link: ROUTES.LOG_LIST,
    name: "Activity Logs",
  },
  // {
  //   link: ROUTES.LOG_API,
  //   name: 'Log API'
  // },
  {
    link: ROUTES.INDEX_LOG,
    name: "Index Log",
  },
  {
    link: ROUTES.VOTE_STATISTICS,
    name: "Vote Statistics",
  },

];

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.OPERATOR_LIST, component: <OperatorList /> },
  { path: ROUTES.USER_ROLE, component: <RoleList /> },
  { path: ROUTES.USER_PROFILE, component: <Profile /> },

  {
    path: ROUTES.TREND_INDEX_CATEGORY_MANAGEMENT,
    component: (
      <CategoryList
        titlePage="Trend Index Category Management"
        typeCategory={CATEGORY_TYPE.TREND_INDEX}
      />
    ),
  },
  {
    path: ROUTES.STOCK_CATEGORY_MANAGEMENT,
    component: (
      <CategoryList
        titlePage="Stock Category Management"
        typeCategory={CATEGORY_TYPE.STOCK}
      />
    ),
  },

  {
    path: ROUTES.TREND_INDEX_KEYWORD_MANAGEMENT,
    component: (
      <TrendIndexKeywordManagement
        titlePage="Trend Index Keyword Management"
        typeCategory={CATEGORY_TYPE.TREND_INDEX}
      />
    ),
  },
  {
    path: ROUTES.STOCK_KEYWORD_MANAGEMENT,
    component: (
      <StockKeywordManagement
        titlePage="Stock Keyword Management"
        typeCategory={CATEGORY_TYPE.STOCK}
      />
    ),
  },

  {
    path: ROUTES.PARTICIPATION_MANAGEMENT,
    component: <ParticipationManagement />,
  },
  {
    path: ROUTES.PARTICIPATION_TEMPLATE_MANAGEMENT,
    component: <ParticipationTemplateManagement />,
  },
  {
    path: ROUTES.PARTICIPATION_CATEGORY_MANAGEMENT,
    component: <ParticipationCategoryManagement />,
  },
  {
    path: ROUTES.PARTICIPATION_TICKET_MANAGEMENT,
    component: <ParticipationTicketManagement />,
  },
  { path: ROUTES.MEDIA_MANAGEMENT, component: <MediaManagement /> },
  {
    path: ROUTES.TREND_INDEX_SETTINGS_MANAGEMENT,
    component: <TrendIndexSetting />,
  },
  {
    path: ROUTES.SEARCH_INDEX_MANAGEMENT,
    component: <SearchIndexManagement />,
  },
  {
    path: ROUTES.SOCIAL_INDEX_MANAGEMENT,
    component: <SocialIndexManagement />,
  },
  { path: ROUTES.VIRAL_INDEX_MANAGEMENT, component: <ViralIndexManagement /> },
  { path: ROUTES.NEWS_INDEX_MANAGEMENT, component: <NewsIndexManagement /> },
  { path: ROUTES.STOCK_INDEX_MANAGEMENT, component: <StockIndexManagement /> },
  {
    path: ROUTES.PARTICIPATION_INDEX_MANAGEMENT,
    component: <ParticipationIndexManagement />,
  },
  { path: ROUTES.REPORT_ANALYSIS, component: <ReportAnalysis /> },


  { path: ROUTES.PAYMENT_MANAGEMENT, component: <PaymentManagement /> },
  { path: ROUTES.PAYMENT_HISTORY, component: <PaymentHistory /> },
  { path: ROUTES.SUBSCRIPTION_MANAGEMENT, component: <SubscriptionManagement /> },

  { path: ROUTES.RANKING_MONITORING, component: <RankingMonitoring /> },
  { path: ROUTES.COLLECTING_MONITORING, component: <CollectingMonitoring /> },

  { path: ROUTES.CHART_RANKING_SINGER, component: <ChartRankingSinger /> },
  

  {
    path: ROUTES.REGULAR_MEMBER_MANAGEMENT,
    component: <RegularMemberManagement />,
  },
  {
    path: ROUTES.BUSINESS_MEMBER_MANAGEMENT,
    component: <BusinessMemberManagement />,
  },
  // {
  //   path: ROUTES.SUB_ACCOUNT_MANAGEMENT,
  //   component: <SubAccountManagement />,
  // },
  {
    path: ROUTES.OFFICIAL_MEDIA_MANAGEMENT,
    component: <ManageOfficialMedia />,
  },
  { path: ROUTES.OFFICIAL_MEDIA_RANKING, component: <OfficialMediaRanking /> },
  {
    path: ROUTES.CATEGORY_QUALIFICATION_SETTINGS,
    component: <CategoryQualificationSetting />,
  },

  { path: ROUTES.INDEX_SETTING, component: <IndexSetting /> },
  { path: ROUTES.GENERAL_SETTING, component: <GeneralSetting /> },

  { path: ROUTES.LOG_LIST, component: <LogList /> },

  { path: ROUTES.INDEX_LOG, component: <IndexLog /> },

  { path: ROUTES.VOTE_STATISTICS, component: <VoteStatistics /> },
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: ROUTES.LOGOUT, component: <Logout /> },
  { path: ROUTES.LOGIN, component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES, ROUTE_NAMES };
