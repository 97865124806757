import { createAsyncThunk } from "@reduxjs/toolkit";
import keywordApi from 'api/keywordApi';
import { ENUM_STATUS_KEYWORD_RANKING } from "helpers/constans";
import { formatQueryParams } from "helpers/format";

export const getKeywords = createAsyncThunk("Keywords", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywords(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

let abortController: AbortController | null = null;

export const getKeywordsMonitor = createAsyncThunk("Keywords Monitor",
  async (params: any = {}, { rejectWithValue }) => {
    try {
      const response = await keywordApi.keywordsMonitor(formatQueryParams(params)); // { signal } as any
      return response;
    } catch (error) {
      return error;
    }
  });


  export const getKeywordsSearchNews = createAsyncThunk("Keywords Search News",
  async (params: any = {}, { rejectWithValue }) => {
    try {
      const response = await keywordApi.keywordsSearchNews(formatQueryParams(params)); // { signal } as any
      return response;
    } catch (error) {
      return error;
    }
  });
  


export const getStockKeywords = createAsyncThunk("Stock Keywords", async (params: any = {}) => {
  try {
    const response = await keywordApi.stockKeywords(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getKeywordsByCategory = createAsyncThunk("Keywords By Category", async (params: any = {}) => {
  try {
    const categoryId = params?.category_id;
    const paramsClone = structuredClone(params);
    delete paramsClone.category_id;
    const response = await keywordApi.keywordsByCategory(categoryId, formatQueryParams(paramsClone))
    return response;
  } catch (error) {
    return error;
  }
});

export const getCollecting = createAsyncThunk("Keywords Collecting", async (params: any = {}) => {
  try {
    const response = await keywordApi.collecting(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getNewsSearchingKeyword = createAsyncThunk("News Searching Keyword", async (params: any = {}) => {
  try {
    const response = await keywordApi.newsSearchingKeyword(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordSummariesStatus = async (params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordSummariesStatus(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getRankingMonitorSummaries = async (params: any = {}) => {
  try {
    const response = await keywordApi.getRankingMonitorSummaries(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getQueryInfoApprove = async (params: any = {}) => {
  try {
    const response = await keywordApi.getQueryInfoApprove(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};


export const getKeyword = async (id: number) => {
  try {
    const response = await keywordApi.getKeyword(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeyword(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.putKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteKeywordOfCategories = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeywordOfCategories(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const downloadExcelKeywords = async (params: any = {}) => {
  try {
    const response = await keywordApi.downloadExcelKeywords(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const changeStatusKeyword = async (data: any = {}) => {
  try {
    // add keyword to activate
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)) {
      const response = await keywordApi.postKeywordActive({ keyword_ids: data?.keyword_ids, frequency_type: data?.frequency_type, date: data?.date });
      return response;
    }

    // add keyword to exclude
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.EXCLUDED)) {
      const response = await keywordApi.postKeywordExclude({ keyword_ids: data?.keyword_ids });
      return response;
    }
    // cancel exclude
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED)) {
      const response = await keywordApi.deleteKeywordExclude({ keyword_ids: data?.keyword_ids });
      return response;
    }

    // add keyword to hidden
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN)) {
      const response = await keywordApi.postKeywordHide({ keyword_ids: data?.keyword_ids, frequency_type: data?.frequency_type, date: data?.date });
      return response;
    }
    // cancel hidden
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN)) {
      const response = await keywordApi.deleteKeywordHide({ keyword_ids: data?.keyword_ids, frequency_type: data?.frequency_type, date: data?.date });
      return response;
    }

    // // confirm exclude
    // const response = await keywordApi.putKeywordExclude({ keyword_id: data?.id, category_id: data?.category_id });
    // return response;

  } catch (error) {
    return error;
  }

};

export const addConfirmKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.addConfirmKeyword(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelConfirmKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.cancelConfirmKeyword(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putInfoExtractKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.putInfoExtractKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateKeywordRaw = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeywordRaw(data)
    return response;
  } catch (error) {
    return error;
  }
};
